.bx {
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #444;
  gap: 4px;
  display: flex;
  align-items: center;
  position: relative;
}
.bx a {
  color: black;
  text-decoration: none;
}
.bx .caret {
  position: absolute;
  right: 10px;
}
.bx .tx {
  font-size: 14px;
  padding-left: 10px;
}
.dx-card {
  width: '90%';
  height: 114px;
}
.black-card {
  border-radius: 12px;
  background-color: black;
  color: white;
}
.yellow-card {
  border-radius: 12px;
  background-color: rgba(248, 176, 43, 1);
  color: black;
}
.gray-card {
  border-radius: 12px;
  background-color: #e9ebec;
  color: black;
}

.light-yellow-card {
  border-radius: 12px;
  background-color: #fceab0;
  color: black;
}
.pink-card {
  border-radius: 12px;
  background-color: #fef0eb;
  color: black;
}
.dx-card .t1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.dx-card .t2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
.dx-card .circle {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
}
.dx-card .gt {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.44);
  width: 60px;
  height: 20px;
  padding: 2px, 3px, 2px, 3px;
  border-radius: 4px;
  font-size: 12px;
  color: limegreen;
  font-weight: bold;
}
.text-2 {
  display: inline-block;
  font-size: 12px;
  padding-top: 10px;
}
.searchbar-container {
  position: relative;
  display: flex;
  align-items: center;
}
.close-btn {
  position: relative;
  right: 20px;
  cursor: pointer;
}
.searchbar-icon {
  position: absolute;
  left: 20px;
}
.searchbar-container > input {
  padding-left: 30px;
}
.avatar {
  background-color: #fdf2d0;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  text-align: center;
}
.nameText {
  font-family: 'Switzer';
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.light-gray-card {
  border-radius: 12px;
  background-color: #f7f7f7;
  color: black;
  padding: 20px;
}

.light-gray-business {
  border-radius: 20px;
  background-color: #f7f7f7;
  color: black;
  padding: 20px;
  margin-bottom: 40px;
}
.business-employee {
  margin: 20px 0;
  width: 1084px;
  height: 112px;
  padding: 24px 32px;
  gap: 40px;
  border-radius: 16px;
  opacity: 1;
  display: flex; 
  align-items: center; 
  background: #F7F7F7;
}

.setting-profile {
  display: flex;
  gap: 64px;
}

.body-text {
  max-width: 230px; 
  color: #817f7f;
}

.radio-group {
  height: 30px;
  display: flex;
  border: 1px solid #bcbcbd; 
  border-radius: 5px;
  width: 31.5%; 
}

.radio-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px 
}

.radio-box:first-child .box {
  border-left: none;
}

.radio-box input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radio-box .box {
  width: 50px; 
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 14px;
  border-left: 1px solid #bcbcbd;
}

.radio-box input[type="radio"]:checked + .box {
  background-color: #F8B02B;
  border-radius: 5px;
}

.radio-box .box::before {
  content: attr(data-text); 
}

.divider {
  height: 1px;
  background-color: #ddd;
  margin: 15px 0;
}

.status {
  display: inline-block;
  padding: 4px 6px;
  height: 28px;
  border-radius: 9px;
  border: 1px solid;
  text-align: center;
  line-height: 18px; 
}

.status.active {
  background: #3CC13B;
  color: #3CC13B;
}

.status.suspended {
  background: #feecec;
  color: #B00020;
  border: 1px solid #B00020;
}

.status.revoked {
  padding: 4px 12px;
  background: #B00020;
  color: #B00020;

}

.status.pending {
  color: #F8B02B;
  background: #FDF2D0;
  
}













