.input-wrapper{
    position: relative;
}
input{
    border-radius: 8px !important;
}
.input-icon{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
}
.recovery-text{
text-decoration: none;
float: right;
color: black;
font-size: 14px;
}