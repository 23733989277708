.btn-group-x{
    height: 40px;
    border-radius: 8px;
    border-color: rgba(211, 214, 217, 1);
    border-width: 1px;
    border-style: solid;
    width: 189px;
    overflow: hidden;
}
.inner-btn{
    height: 40px;
    cursor: pointer;
    font-size: 16px;
    font-family: Switzer;
}
.inner-btn-active{
    background-color: rgb(255, 153, 0);
}
.animateY{
animation: translateYAnimation 3s ease-in-out infinite;
}
.animateYReverse{
    animation: translateYAnimationReverse 3s ease-in-out infinite;
    }
/* Keyframes for the translate animation */
@keyframes translateYAnimation {
  0% {
    transform: translate(0, 0); /* Initial position */
  }
  100% {
    transform: translate(126, 0); /* Return to original position */
  }
}
@keyframes translateYAnimationReverse {
    0% {
      transform: translate(126, 0); /* Initial position */
    }
    100% {
      transform: translate(0, 0); /* Return to original position */
    }
  }