.pagination-container{
    float: right;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination-btn{
font-size: 14px;
padding: 0px 10px;
cursor: pointer;

}
.pagination-btn-active{
    font-size: 14px;
    padding: 0px 10px;
    background: #F8B02B;
    border-radius: 4px;
}
.bx-rw{
    min-width:95px;
    height:40px;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    cursor: pointer;
}
.bx-rw-dropdown{
padding: 0px;
background-color: white;
z-index: 999999;
width: 90%;
box-shadow: 0px 3px 4px 2px rgba(0,0,0,0.12);
-webkit-box-shadow: 0px 3px 4px 2px rgba(0,0,0,0.12);
-moz-box-shadow: 0px 3px 4px 2px rgba(0,0,0,0.12);
list-style: none;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
}
.bx-rw-dropdown li{
padding:5px 10px;
border-bottom: solid 1px rgba(0,0,0,0.12); ;
cursor: pointer;
}