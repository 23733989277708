.form-check-label{
    font-size: 14px;
    color:#B9B9BB;
    padding-left: 10px;

}
input[type="checkbox"]:checked {
    border-color: #F8B02B;
    background-color: #F8B02B;
}
.form-check-label a{
    font-size: 14px;
    color:#000000;
    text-decoration: underline;
    font-weight: bold;
}
.input-wrapper{
    position: relative;
}
.radius-8{
    border-radius: 8px !important;
    position: relative;
}
.radius-8 label{
font-size: 10px;
color: #A0A3A6;
}
input{
    font-size: 16px;
}
.input-icon{
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.recovery-text{
text-decoration: none;
float: right;
color: black;
font-size: 14px;
}